import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import renderSectionElement from "../helpers/renderSectionElm"

const playTemplate = ({ pageContext, data }) => {
  const { pageHeadline, pageText, content, seo, next } = pageContext

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={data.metaImage.childImageSharp.fixed.src}
      />
      <div className="main-container">
        <section>
          <h2>{pageHeadline}</h2>
          <p
            className="subtext-h1"
            dangerouslySetInnerHTML={{ __html: pageText }}
          ></p>
        </section>
        {content.map(({ section }, index) => (
          <section key={`content_item_${index}`} data-sal="slide-up">
            {section.map((elm, i) => renderSectionElement(elm, i, data.images))}
          </section>
        ))}
      </div>
      <section className="next-play">
        <div className="main-container">
          <Link to={next.path}>
            <div className="next-container">
              <h6>Explore next experiment</h6>
              <div className="horizontal-line"></div>
            </div>
            <h2>{next.headline}</h2>
            <p className="subtext-h2">{next.teaser.description}</p>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AllFilesToPlayPath($regex: String, $metaImg: String) {
    images: allFile(filter: { relativePath: { regex: $regex } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    metaImage: file(relativePath: { regex: $metaImg }) {
      childImageSharp {
        fixed(width: 800) {
          src
        }
      }
    }
  }
`

export default playTemplate
