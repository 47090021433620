import React from "react"
import Img from "gatsby-image"
import determineImgClass from "./determineImgClass"

const renderSectionElement = (elm, index, images) => {
  if (elm.hasOwnProperty("multiImages")) {
    const foundImgClass = elm.multiImages
      ? determineImgClass(elm.multiImages)
      : null
    return (
      <div
        className={`multi-image-container${
          elm.multiImages.length === 1 && elm.multiImages[0].centred
            ? " center"
            : ""
        }`}
        key={`mic_${index}`}
      >
        {elm.multiImages.map(({ name, extension, imgClass }, i) => {
          if (extension) {
            return (
              <div
                style={{ width: "100%" }}
                key={`${name}_${i}`}
                className={foundImgClass}
              >
                <div className={`gatsby-image-wrapper ${imgClass}`}>
                  <img
                    src={`../../${name}.${extension}`}
                    alt={name}
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
              </div>
            )
          }
          const img = images.edges.find(({ node }) => node.name === name)
          return (
            img && (
              <div
                style={{ width: "100%" }}
                key={`${name}_${i}`}
                className={foundImgClass}
              >
                <Img
                  fluid={img.node.childImageSharp.fluid}
                  alt={name}
                  imgStyle={{ objectFit: "contain" }}
                  className={imgClass}
                />
              </div>
            )
          )
        })}
      </div>
    )
  }

  if (elm.hasOwnProperty("container")) {
    return (
      <div className="main-text-container" key={`main_text_container_${index}`}>
        {elm.container.map((containerElm, i) => {
          if (containerElm.hasOwnProperty("headline")) {
            return <h3 key={`headline_${i}`}>{containerElm.headline}</h3>
          }

          if (containerElm.hasOwnProperty("paragraph")) {
            return (
              <p
                className={`main-text ${containerElm.class || ""}`}
                key={`p_${i}`}
                dangerouslySetInnerHTML={{ __html: containerElm.paragraph }}
              ></p>
            )
          }

          if (containerElm.hasOwnProperty("image")) {
            const img = images.edges.find(
              ({ node }) => node.name === containerElm.image
            )
            return (
              <Img
                fluid={img.node.childImageSharp.fluid}
                alt={img.node.name}
                key={`img_${i}`}
              />
            )
          }

          if (containerElm.hasOwnProperty("video")) {
            return (
              <video controls key={`video_${i}`}>
                <source
                  src={`../../video/${containerElm.video}.mp4`}
                  type="video/mp4"
                />
              </video>
            )
          }
          return null
        })}
      </div>
    )
  }

  if (elm.hasOwnProperty("caption")) {
    return <figcaption key={`caption_${index}`}>{elm.caption}</figcaption>
  }
  return null
}

export default renderSectionElement
