const determineImgClass = arr => {
  switch (arr.length) {
    case 1:
      return arr[0].centred ? "double-img" : "single-img"
    case 2:
      return "double-img"
    case 3:
      return "triple-img"
    default:
      return ""
  }
}

export default determineImgClass
